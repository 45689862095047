<template>
  <section class="new_cssa">
    
    <div style="display:flex;" >
        <el-select v-model="mymeetingValue" style="width:320px" placeholder="选择与我有关的会议进行筛选" :clearable="true">
          <el-option
            v-for="item in myMeetings"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <!--工具条-->
        <toolbar
          :buttonList="buttonList"
          @callFunction="callFunction"
          :buttonListmsg="meetingmsg"
        ></toolbar>
    </div>
    <!--列表内容-->
    <el-table
      :data="meetings"
      highlight-current-row
      @current-change="selectCurrentRow"
      :row-style="{height:'40px'}"
      :cell-style="{padding:'0px'}"
      @row-dblclick="checkInfo"
      :header-cell-style="{background:'#dce4f1',color:'#000000'}"
      :row-class-name="tableRowClassName"
      style="width: 100%"
    >
      <el-table-column type="index"></el-table-column>
      <el-table-column label="会议名称" min-width="200px" prop="MeetingName" align="center">
        <template scope="scope">
          <span style="cursor:default">{{scope.row.MeetingName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="问题分类" prop="QuestionType"  align="center">
        <template scope="scope">
          <el-tag type="warning">{{formatQuestionType(scope.row.QuestionType)}}</el-tag>
          <!-- <span style="cursor:default">{{formatQuestionType(scope.row.QuestionType)}}</span> -->
        </template>
      </el-table-column>
      <el-table-column label="问题名称" prop="QuestionName" min-width="150px" align="center"></el-table-column>
      <!-- <el-table-column label="问题描述" prop="QuestionDescription" align="center"></el-table-column> -->
      <!-- <el-table-column label="会议日期" prop="MeetingTime" :formatter="formatMeetingTime" align="center"></el-table-column> -->
      <!-- <el-table-column label="参会人员" prop="PartInUserName" align="center"></el-table-column> -->
      <!-- <el-table-column label="审批人" prop="ApprovalName" align="center"></el-table-column> -->
      <!-- <el-table-column label="抄送人" prop="CCUserName" align="center"></el-table-column> -->
      <!-- <el-table-column label="备注" prop="Remark" align="center"></el-table-column> -->
      <el-table-column label="发布人" prop="CreatedBy" align="center"></el-table-column>
      <el-table-column
        label="发布时间"
        prop="CreatedOn"
        align="center"
      ></el-table-column>
      <el-table-column label="操作"
                       min-width="80px">
        <template slot-scope="scope">
          <!-- <p><el-link type="primary" :underline="false" @click="checkInfo(scope.row)" v-if="scope.row.Status != 5">查看</el-link></p> -->
          <p>
            <el-tooltip content="点击查看或参与会议问题讨论" placement="left" effect="light">
              <el-link type="primary" :underline="false" @click="checkInfo(scope.row)" >参与讨论</el-link>
              <!-- <el-link type="primary" :underline="false" @click="yearplanchoose(scope.row)">{{scope.row.TagName==null?'关联':scope.row.TagName}}</el-link> -->
            </el-tooltip>
          </p>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination align='center'
                   @current-change="handleCurrentChange"
                   :current-page="currentPage"
                   :page-size="pageSize"
                   layout="prev, pager, next, jumper"
                   :page-count="total"
                   :total="totaldata">
    </el-pagination>
    <!-- <br/> -->
    <!--新增、编辑界面-->
    <el-dialog
      :visible.sync="addFormVisible"
      v-model="addFormVisible"
      @close="diaclose"
      center
    >
    <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">{{title}}</span>
    </template>
      <el-form
        :model="addForm"
        label-width="85px"
        :rules="addFormRules"
        ref="addForm"
        style="white-space:nowrap;"
      >
        <el-form-item label="会议名称:" prop="MeetingName">
          <el-select v-model="addForm.MeetingId" placeholder="请选择" style="width:100%;">
            <el-option
              v-for="item in meetingOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="问题分类:" prop="QuestionType">
          <el-select v-model="addForm.QuestionType" placeholder="请选择" style="width:100%;">
            <el-option
              v-for="item in qsTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="问题名称:"
                      prop="QuestionName">
          <el-input v-model="addForm.QuestionName"
                    maxlength="30"
                    show-word-limit
                    :readonly="false"></el-input>
        </el-form-item>
        <el-form-item label="问题描述:"
                      prop="QuestionDescription">
          <el-input v-model="addForm.QuestionDescription"
                    type="textarea"
                    maxlength="500"
                    show-word-limit></el-input>
        </el-form-item>
        
        <!-- <el-form-item label="备注:" prop="Remark">
          <el-input v-model="addForm.Remark" type="textarea" maxlength="50" show-word-limit></el-input>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="goon" @click.native="addSubmit">提交</el-button>
      </div>
    </el-dialog>

    <meetingqs v-if="checkVisible" :data="checkForm" @closeFunction="checkclose" ></meetingqs>

  </section>
</template>
<script>
import util from "../../../util/date";
import {
  GetUserPostList,
  AddMeetingQuestionReview,
  AddMeetingQuestion,
  QueryAboutMeMeetingList,
  QueryReviewByQuestionId,
  QueryPageMeetingQuestionListByUsercode,
  DeleteMeetingQuestionById,
  UpdateMeetingQuestion
} from "../../api/oa";
import {Loading} from 'element-ui'
import { getButtonList } from "../../promissionRouter";
import Userchoosetool from "../../components/Userchoosetool";
import Toolbar from "../../components/Toolbar";
import UserChoose from "../../components/UserChoose";
import editor from '../../components/editor.vue';
import meetingqs from '../../components/meetingqs.vue'
export default {
  components: { editor, meetingqs, Toolbar, Userchoosetool,UserChoose},
  data() {
    return {
      visible:false,
      loading: '',
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      meetings: [],
      reviews:[],
      buttonList: [],
      meetingOptions: [],
      qsTypeOptions: [
        {
          value:1,
          label:'售前咨询',
        },
        {
          value:2,
          label:'售中支持',
        },
        {
          value:3,
          label:'售后服务',
        },
        {
          value:4,
          label:'资质问题',
        },
        {
          value:5,
          label:'产销对接',
        },
        {
          value:6,
          label:'人事管理',
        },
        {
          value:7,
          label:'财务管理',
        },
        {
          value:8,
          label:'信息化服务',
        }
      ],
      filters: {
        name: ""
      },
      ccusername:'',
      ccusername2:'',
      userid: [], //暂存选中角色下所有用户的id
      choosedusers: [],//抄送人
      checkForm:{
        MeetingName:null,
        QuestionType:null,
        QuestionName:null,
        QuestionDescription:null,
      },
      mymeetingValue:null,
      myMeetings: [],
      checkVisible:false,
      addUserVisible:false,
      addUserVisible2:false,
      addFormVisible: false,
      editFormVisible: false,
      editCCFormVisible: false,
      title: "新增会议问题",
      departmsg: "departmsg",
      catchdepart: null,
      currentRow: null,
      Meetingstatus: "每天",
      meetingmsg: "meetingquestionlist",
      assignpeo: "assignpeo",
      canhuirenyuan: "canhuirenyuan",
      doublepeople: [],
      partinpeople: ['canhuirenyuan'],
      addForm: {
        Id: null,
        MeetingId: null,
        MeetingName: null,
        QuestionType: null,
        QuestionName: null,
        QuestionDescription: null,
        CreatedBy: null,
        CreatedOn: null,
        Remark: null
      },
      addFormRules: {
        MeetingName: [
          { required: true, message: "请填写会议名称", trigger: "blur" }
        ],
        QuestionType: [
          { required: true, message: "请选择问题分类", trigger: "blur" }
        ],
        QuestionName: [
          { required: true, message: "请填写问题名称", trigger: "blur" }
        ],
        QuestionDescription:[
          { required: true, message: "请填写问题描述", trigger: "blur" }
        ],
      },
      editForm: {
        Id: null,
        ApprovalName: null,
        ApprovalCode: null
      },
      editCCForm: {
        Id: null,
        CCUserName: null,
        CCUserCode: null
      },
      content:null
    };
  },
  methods: {
    checkclose () {
      this.checkVisible = false;
    },
    handleCloseReply(a)
    {
      a.reply = false;
      this.$forceUpdate()
    },
    handleOpenReply(a)
    {
      this.reviews.forEach(element => {
        if(element.Id != a.Id)
        {
          element.reply = false;
        }
      });
      a.reply = !a.reply;
      this.$forceUpdate()
    },
    myname()
    {
      var user = JSON.parse(window.localStorage.user);
      var dptname = window.localStorage.deptName;
      return dptname + " "+user.name;
    },
    getReplyReview(arr,id)
    {
      // console.log(arr);
      // console.log(id);
      var o ={};
      for(var i=0;i<arr.length;i++)
      {
        if(arr[i].Id==id)
        {
          o = arr[i];
          break;
        }
      }
      return o;
    },
    catchData(c)
    {
      this.content=c;
    },
    
    loadReview()
    {
      QueryReviewByQuestionId({
        questionId: this.checkForm.QuestionId
      }).then(res => {
        // console.log(res);
        this.reviews = res.data.response;
        // console.log(this.reviews)
      });
    },
    diaopened()
    {
      this.loadReview();
    },
    checkInfo(row)
    {
      // console.log(row);
      this.checkVisible = true;
      this.checkForm.QuestionId = row.Id;
      this.checkForm.QuestionName=row.QuestionName;
      this.checkForm.MeetingId=row.MeetingId;
      this.checkForm.MeetingName=row.MeetingName;
      this.checkForm.QuestionType=row.QuestionType;
      this.checkForm.QuestionDescription=row.QuestionDescription;

      // this.$message({
      //   message: "开发中……",
      //   type: "success"
      // });
    },
    handleChooseCCUser()
    {
        this.addUserVisible=true;
    },
    handleChooseCCUser2()
    {
        this.addUserVisible2=true;
    },
    //监听子组件事件 选择人员回调
    chooseUserCallBack2(data) {
        console.log(data);
        var newdata = data.map(function (item) {
            return item["Id"];
        });
        var namenewdata = data.map(function (item) {
            return item["UserNameClaim"];
        });
        console.log(newdata);

        var str = "";
        for (var i = 0; i < namenewdata.length; i++) {
            str += namenewdata[i]+ ",";
        }
        if (str.length > 0) {
            str = str.substr(0, str.length - 1);
        }

        var idstr = "";
        for (var i = 0; i < newdata.length; i++) {
            idstr += newdata[i]+ ",";
        }
        if (idstr.length > 0) {
            idstr = idstr.substr(0, idstr.length - 1);
        }
        this.ccusername2=str;
        this.editCCForm.CCUserCode = idstr;
        this.editCCForm.CCUserName = str;
        this.addUserVisible2=false;
    },
    //监听子组件事件 选择人员回调
    chooseUserCallBack(data) {
        console.log(data);
        var newdata = data.map(function (item) {
            return item["Id"];
        });
        var namenewdata = data.map(function (item) {
            return item["UserNameClaim"];
        });
        console.log(newdata);

        var str = "";
        for (var i = 0; i < namenewdata.length; i++) {
            str += namenewdata[i]+ ",";
        }
        if (str.length > 0) {
            str = str.substr(0, str.length - 1);
        }

        var idstr = "";
        for (var i = 0; i < newdata.length; i++) {
            idstr += newdata[i]+ ",";
        }
        if (idstr.length > 0) {
            idstr = idstr.substr(0, idstr.length - 1);
        }
        this.ccusername=str;
        this.addForm.CCUserCode = idstr;
        this.addForm.CCUserName = str;
        this.addUserVisible=false;
    },
    callFunction(item) {
      this.filters = {
        name: item.search
      };
      this[item.Func].apply(this, item);
    },
    formatCreatedOn: function(row, colume) {
      return !row.LastUpdateTime || row.LastUpdateTime == ""
        ? ""
        : util.formatDate.format(new Date(row.LastUpdateTime), "yyyy-MM-dd");
    },
    formatMeetingTime: function(row, colume) {
      return !row.MeetingTime || row.MeetingTime == ""
        ? ""
        : util.formatDate.format(new Date(row.MeetingTime), "yyyy-MM-dd");
    },
    formatQuestionType: function(QuestionType) {
      // console.log(QuestionType)
      if(QuestionType==1)
      {
        return '售前咨询';
      }
      else if(QuestionType == 2)
      {
        return '售中支持';
      }
      else if(QuestionType == 3)
      {
        return '售后服务';
      }
      else if(QuestionType == 4)
      {
        return '资质问题';
      }
      else if(QuestionType == 5)
      {
        return '产销对接';
      }
      else if(QuestionType == 6)
      {
        return '人事管理';
      }
      else if(QuestionType == 7)
      {
        return '财务管理';
      }
      else if(QuestionType == 8)
      {
        return '信息化服务';
      }else{
        return '';
      }
    },
    handleCurrentChange (val) {
      this.page = val;
      this.queryMeetingQuestions();
    },
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    getMeetingQuestions()
    {
      this.queryMeetingQuestions();
    },
    queryMeetingQuestions() {
      var user = JSON.parse(window.localStorage.user);
      QueryPageMeetingQuestionListByUsercode({
        userCode: user.sub,
        meetingId: this.mymeetingValue,
        searchKey: this.filters.name,
        pageIndex: this.page,
        pageSize: this.pageSize,
      }).then(res => {
        this.meetings = res.data.response.data;
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
      });
    },
    //新增
    handleAdd() {
      this.title="新增会议问题";
      this.addFormVisible = true;
      this.addForm.Id = null;
      this.addForm.MeetingId = null;
      this.addForm.QuestionType = null;
      this.addForm.QuestionName = null;
      this.addForm.QuestionDescription = null;
      this.addForm.Remark = null;
    },
    //新增提交
    addSubmit() {
      let para = Object.assign({}, this.addForm);
      var user = JSON.parse(window.localStorage.user);
      para.CreatedId = user.sub;
      para.CreatedBy = user.name;
      para.CreatedOn = new Date();
      if (
        this.addForm.MeetingId == null ||
        this.addForm.QuestionType == null ||
        this.addForm.QuestionName == null ||
        this.addForm.QuestionDescription == null
      ) {
        this.tipMessage();
        return;
      }else{
        if(
          this.addForm.MeetingId == '' ||
          this.addForm.QuestionType == '' ||
          this.addForm.QuestionName == '' ||
          this.addForm.QuestionDescription == ''
          )
          {
            this.tipMessage();
            return;
          }
      }
      var exists=false;
      for(var a=0;a<this.meetings.length;a++)
      {
        if(this.meetings[a].QuestionName==this.addForm.QuestionName)
        {
          exists=true;
          break;
        }
      }
      if(exists && !this.addForm.Id)
      {
        this.$message({
          message: "问题名称不允许重复，请检查！",
          type: "warning"
        });
        return;
      }
      
      this.Loadingstart();
      for(var a=0;a<this.meetingOptions.length;a++)
      {
        if(this.meetingOptions[a].value==this.addForm.MeetingId)
        {
          var meetingName = this.meetingOptions[a].label;
          this.addForm.MeetingName = meetingName;
          para.MeetingName = meetingName;
          break;
        }
      }
      if(this.addForm.Id)
      {
        UpdateMeetingQuestion(para).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success"
            });
            this.Loadignend();
            this.$refs["addForm"].resetFields();
            this.addFormVisible = false;
            this.queryMeetingQuestions();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error"
            });
            this.Loadignend();
          }
        });
      }else{
        AddMeetingQuestion(para).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success"
            });
            this.Loadignend();
            this.$refs["addForm"].resetFields();
            this.addFormVisible = false;
            this.queryMeetingQuestions();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error"
            });
            this.Loadignend();
          }
        });
      }
    },
    handleEdit()
    {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "warning"
        });
        return;
      }
      var user = JSON.parse(window.localStorage.user);
      if(row.CreatedId!=user.sub)
      {
        this.$message({
          message: "当前登录人非会议问题创建人，无法编辑",
          type: "warning",
        });
        return;
      }
      this.addFormVisible = true;
      this.title="编辑会议问题";
      this.addForm.Id = row.Id;
      this.addForm.MeetingId = row.MeetingId;
      this.addForm.MeetingName = row.MeetingName;
      this.addForm.QuestionType = row.QuestionType;
      this.addForm.QuestionName = row.QuestionName;
      this.addForm.QuestionDescription = row.QuestionDescription;
      this.addForm.CreatedBy = row.CreatedBy;
      this.addForm.CreatedOn = row.CreatedOn;
      this.addForm.Remark = row.Remark;
    },
    //修改审批人
    handleEdit2() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error"
        });
        return;
      }
      this.addFormVisible = true;
      this.addForm.Id = row.Id;
      this.addForm.MeetingName = row.MeetingName;
      this.addForm.QuestionType = row.QuestionType;
      this.addForm.QuestionName = row.QuestionName;
      this.addForm.QuestionDescription = row.QuestionDescription;
      this.addForm.CreatedBy = row.CreatedBy;
      this.addForm.CreatedOn = row.CreatedOn;
      this.addForm.Remark = row.Remark;
    },
    //修改抄送人
    handleEditCCUser() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error"
        });
        return;
      }
      this.editCCFormVisible = true;
      this.editCCForm.Id = row.Id;
    },
    handleCopy()
    {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要复制的一行数据！",
          type: "warning"
        });
        return;
      }
      this.addFormVisible = true;
      this.addForm.MeetingId = row.MeetingId;
      this.addForm.MeetingName = row.MeetingName;
      this.addForm.QuestionType = row.QuestionType;
      this.addForm.QuestionName = row.QuestionName;
      this.addForm.QuestionDescription = row.QuestionDescription;
      this.addForm.CreatedBy = row.CreatedBy;
      this.addForm.CreatedOn = row.CreatedOn;
      this.addForm.Remark = row.Remark;
      // console.log(row);

    },
    //删除
    handleDelete() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要删除的一行数据！",
          type: "warning"
        });
        return;
      }
      var user = JSON.parse(window.localStorage.user);
      if(row.CreatedId!=user.sub)
      {
        this.$message({
          message: "当前登录人非会议问题创建人，无法删除",
          type: "warning",
        });
        return;
      }
      this.$confirm("确定要删除选中的会议问题吗？", "提示", {}).then(() => {
        this.Loadingstart();
        let para = { meetingQuestionId: row.Id };
        DeleteMeetingQuestionById(para).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success"
            });
            this.Loadignend();
            this.currentRow = null;
            this.queryMeetingQuestions();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error"
            });
            this.Loadignend();
          }
        });
      });
    },
    diaclose() {
      this.$refs.addForm.resetFields();
    },
    tipMessage() {
      this.$message({
        message: "表单填写未完成，请继续填写",
        type: "warning"
      });
    },
    //部门选择回调
    departcallFunction(departname) {
      this.catchdepart = departname;
    },
    //审核人回调
    assigncallFunction(newdata) {
      this.addForm.ApprovalCode = newdata[0];
      this.editForm.ApprovalCode = newdata[0];
      let para = { userId: newdata[0] };
      GetUserPostList(para).then(res => {
        for (var i = 0; i < res.data.data.length; i++) {
          this.addForm.ApprovalName = res.data.data[0].name;
          this.editForm.ApprovalName = res.data.data[0].name;
        }
      });
    },
      tableRowClassName({row, rowIndex}){
            if (rowIndex % 2 !== 0) {
            return 'warning-row';
            }
            return '';    
       },
       //参会人回调
       partincallFunction(newdata){
         console.log('asdf')
         console.log(newdata)
          let canhuiusers = "";
          let canhuiusercodes = "";
         if(newdata.length == 1){
           this.addForm.PartInUserCode = newdata[0];
           let para = {userId: newdata[0]};
            GetUserPostList(para).then((res) => {
              console.log(res)
              this.addForm.PartInUserName = res.data.data[0].name;
              console.log(this.addForm);
            });
          }else{
            newdata.map(item => {
              canhuiusercodes += item + ",";
              this.addForm.PartInUserCode = canhuiusercodes.replace("null", "");
              let para = {userId: item};
              GetUserPostList(para).then((res) => {
                canhuiusers += res.data.data[0].name + ",";
                this.addForm.PartInUserName =  canhuiusers.replace("null", "");
                this.addForm.PartInUserName = this.addForm.PartInUserName.substr(0, this.addForm.PartInUserName.length - 1);
              });
            })
          }
       },
       //会议发布人回调
       usercallFunction(newdata){
          let fabuusers = "";
          let fabuusercodes = "";
         if(newdata.length == 1){
           this.addForm.PublishCode = newdata[0];
           let para = {userId: newdata[0]};
            GetUserPostList(para).then((res) => {
                    this.addForm.PublishName = res.data.data[0].name;
            });
          }else{
            newdata.map(item => {
              fabuusercodes += item + ",";
              this.addForm.PublishCode = fabuusercodes.replace("null", "");
              let para = {userId: item};
              GetUserPostList(para).then((res) => {
                      fabuusers += res.data.data[0].name + ",";
                      this.addForm.PublishName =  fabuusers.replace("null", "");
                      this.addForm.PublishName = this.addForm.PublishName.substr(0, this.addForm.PublishName.length - 1);
              });
            })
          }
       },
       Loadingstart(){
        　　this.loading = Loading.service({
        　　　　lock:true,
        　　　　text:'加载中...',
        　　　　background:'rgba(0,0,0,0.1)'
        　　})
        },
        Loadignend(){
        　　this.loading.close();
        }
  },
  mounted() {
    this.queryMeetingQuestions();
    var user = JSON.parse(window.localStorage.user);
    let self=this;
    QueryAboutMeMeetingList({
      usercode: user.sub
    }).then(res => {
      res.data.response.forEach(element => {
        var obj={};
        obj.value = element.Id;
        obj.label = element.MeetingName;
        self.meetingOptions.push(obj);
        self.myMeetings.push(obj);
      });
      // console.log(res);
      // this.meetings = res.data.response;
    });
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    //第一种写法，每个页面都需要写方法，但是可以做特性化处理
    // this.getButtonList(routers);
    
    //第二种写法，封装到 permissionRouter.js 中
    let  buttons = window.localStorage.buttList? JSON.parse(window.localStorage.buttList) : [];
    this.buttonList = getButtonList(this.$route.path, routers,buttons);
  }
};
</script>
<style scoped>
/deep/.el-table__body tr.current-row > td {
  background: #fabe64 !important;
}
/deep/.el-table .warning-row {
    background: #F0F8FF;
  }
/deep/.el-dialog__header{
    padding:20px 20px 10px;
    /* background-color: #20B2AA; */
}
/deep/.el-icon-close:before{
    color: #FFFFFF;
}
</style>